import socketIOClient from 'socket.io-client';

let _socket = null;

export const connectSocket = async () => {
  if (!_socket) {
    await fetch('/api/socket');
    _socket = socketIOClient('', {
      path: '/api/socket_io',
      addTrailingSlash: false,
    });
  }

  return _socket;
};

export const connectedSocket = () => connectSocket();
