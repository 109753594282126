import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  selectedCriteriaForAnalysis: {
    symbol: '',
    from: '',
    to: '',
  },
};

// Actual Slice
export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    // Action to set query results
    setSelectedCriteriaForAnalysis(state, action) {
      state.selectedCriteriaForAnalysis = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.analysis,
      };
    },
  },
});

export const { setSelectedCriteriaForAnalysis } = analysisSlice.actions;

export const selectSelectedCriteriaForAnalysis = (state) => {
  return state.analysis?.selectedCriteriaForAnalysis;
};

export default analysisSlice.reducer;
