import { MESSAGE_TYPES, SUBSCRIPTION_TYPES } from '@/lib/utils/constants';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import _ from 'lodash';

// Initial state
const initialState = {
  stream: {
    trades: {},
    connection: new Promise(() => {}),
    momentumStocks: [],
  },
};

// Actual Slice
export const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    // Action to set stream results
    updateStream(state, action) {
      const _stream = state.stream;
      const payload = action.payload;

      switch (payload.type) {
        case SUBSCRIPTION_TYPES.TRADES:
          const trade = action.payload.trade;
          const { Symbol: symbol, Price: price } = trade;
          _stream.trades[symbol] = price;
          state.stream = {
            ...state.stream,
            trades: _stream.trades,
          };
          break;
        case MESSAGE_TYPES.MOMENTUM:
          const stocks = action.payload.stocks;
          const updated = _.uniqBy(
            [...stocks, ...state.stream.momentumStocks],
            'Timestamp'
          );
          state.stream = {
            ...state.stream,
            momentumStocks: updated,
          };
          break;
        default:
      }
    },
    setConnectionStatus(state, action) {
      state.stream.connection = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.stream,
      };
    },
  },
});

export const { updateStream, setConnectionStatus } = streamSlice.actions;

export const selectStreamTrades = (state) => state.stream.stream.trades;
export const selectMomentumStocks = (state) =>
  state.stream.stream.momentumStocks;
export const selectStreamConnectionStatus = (state) =>
  state.stream.stream.connection;

export default streamSlice.reducer;
