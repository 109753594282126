import { IconButton } from '@mui/material';
import styles from './Avatar.module.css';
import { User } from 'react-feather';

const Avatar = ({ size, username, url }) => {
  return url ? (
    <img
      className={styles.avatar}
      src={url}
      alt={username}
      width={size}
      height={size}
    />
  ) : (
    <IconButton>
      <User className={styles.avatar} />
    </IconButton>
  );
};

export default Avatar;
