import { MENU_CATEGORIES, MENU_TYPES } from '@/lib/utils/constants';
import { Codesandbox, Cpu, File, Home, Sliders } from 'react-feather';

export const navMenu = (customPages = []) => [
  {
    type: MENU_TYPES.MENU_LIST,
    items: [
      {
        label: 'Home',
        Icon: Home,
        link: '/',
      },
      {
        label: 'Backtest',
        Icon: Codesandbox,
        link: '/backtest',
      },
      {
        label: 'Analyze',
        Icon: Cpu,
        link: '/analyze',
      },
      {
        label: 'Builder',
        Icon: Sliders,
        link: '/backtest',
        hidden: true,
      },
    ],
  },
  {
    type: MENU_TYPES.DIVIDER,
  },
  {
    category: MENU_CATEGORIES.CUSTOM_PAGES,
    type: MENU_TYPES.MENU_LIST,
    items: [
      ...customPages.map(({ title, _id }) => ({
        label: title,
        Icon: File,
        link: `/custom/${_id}`,
      })),
    ],
  },
];
