import { IconButton } from '@mui/material';
import styles from './ThemeSwitcher.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme, setTheme } from 'store/appSlice';
import { DarkMode, LightMode } from '@mui/icons-material';

const ThemeSwitcher = () => {
  const appTheme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const toggleTheme = () => {
    dispatch(setTheme({ theme: appTheme === 'light' ? 'dark' : 'light' }));
  };

  return appTheme === 'dark' ? (
    <IconButton onClick={toggleTheme}>
      <LightMode />
    </IconButton>
  ) : (
    <IconButton onClick={toggleTheme}>
      <DarkMode classes={{ root: styles.dark }} />
    </IconButton>
  );
};

export default ThemeSwitcher;
