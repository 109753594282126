import { Text, TextLink } from '@/components/Text';
// import { ThemeSwitcher } from '@/components/ThemeSwitcher';
import styles from './Footer.module.css';
import Spacer from './Spacer';
import Wrapper from './Wrapper';
import { Box } from '@mui/material';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Wrapper>
        <Box>Made with ❤️, 🔥, and a keyboard by </Box>
        <TextLink href="http://yourstradely.com/" color="success">
          Yours Tradely LLC
        </TextLink>
        <Spacer size={1} axis="vertical" />
      </Wrapper>
    </footer>
  );
};

export default Footer;
