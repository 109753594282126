import '@/assets/base.scss';
import 'node_modules/react-grid-layout/css/styles.css';
import 'node_modules/react-resizable/css/styles.css';

import { Layout } from '@/components/Layout';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'react-hot-toast';
import { CacheProvider } from '@emotion/react';

import { lightTheme, darkTheme } from '@/assets/theme';
import createEmotionCache from '@/assets/createEmotionCache';

// import { connectSocket } from '@/lib/socket';

import { wrapper } from 'store/store';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { selectTheme, setUser } from 'store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentUser } from '@/lib/user';
import { setConnectionStatus, updateStream } from 'store/streamSlice';
import { geSSEStream } from '@/lib/stream';
import { connectedSocket } from '@/lib/socket';
import { MESSAGE_TYPES, SUBSCRIPTION_TYPES } from '@/lib/utils/constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { data, error } = useCurrentUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const appTheme = useSelector(selectTheme);

  const theme = appTheme === 'dark' ? darkTheme : lightTheme;

  useEffect(() => {
    if (data) {
      if (!data.user && !error) {
        dispatch(setUser({ isLoading: false, isValid: false }));
        return;
      }

      if (data.user) {
        dispatch(setUser({ ...data.user, isLoading: false, isValid: true }));
      }
    }
  }, [router, data, error, dispatch]);

  // useEffect(() => {
  //   const establishStreamConnection = async () => {

  //     const stream = await geSSEStream();

  //     stream.onmessage = (e) => {
  //       const streamData = JSON.parse(e.data || '[]');

  //       if (streamData.type === 'CONNECTION') {
  //         if (streamData.connected) _res();
  //         else _rej();
  //       } else {
  //         dispatch(updateStream(streamData));
  //       }
  //     };
  //   };

  //   establishStreamConnection();
  // }, []);

  useEffect(() => {
    const onMount = async () => {
      const socket = await connectedSocket();
      let _res;
      let _rej;

      socket.on('trade', (trade) => {
        dispatch(
          updateStream({
            type: SUBSCRIPTION_TYPES.TRADES,
            trade,
          })
        );
      });

      socket.on(MESSAGE_TYPES.MOMENTUM, (stocks) => {
        dispatch(
          updateStream({
            type: MESSAGE_TYPES.MOMENTUM,
            stocks,
          })
        );
      });

      // Initialize connection status
      dispatch(
        setConnectionStatus(
          new Promise((res, rej) => {
            _res = res;
            _rej = rej;
          })
        )
      );

      socket.on(MESSAGE_TYPES.DATACLIENT_WS_CONNECTED, (status) => {
        if (status) {
          _res();
        }
      });
    };

    onMount();
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <Layout>
            <CssBaseline />
            <Component {...pageProps} />
            <Toaster
              toastOptions={{
                style: {
                  borderRadius: 0,
                },
              }}
            />
          </Layout>
        </ThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default wrapper.withRedux(MyApp);
