import { fetcher } from '../fetch';

export const createPage = async (layout = [], title = 'Untitled') => {
  return await fetcher('/api/pages', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ layout, title }),
  });
};

export const updatePage = async (id, payload) => {
  return await fetcher(`/api/pages/${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...payload }),
  });
};

export const getPages = async () => {
  return await fetcher(`/api/pages`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const deletePage = async (pageId) => {
  return await fetcher(`/api/pages/${pageId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
};
