import { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar } from '@/components/Avatar';
import {
  Button as MuiButton,
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Collapse,
  Container,
} from '@mui/material';
import { ThemeSwitcher } from '@/components/ThemeSwitcher';
import { fetcher } from '@/lib/fetch';
import { useCurrentUser } from '@/lib/user';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import styles from './Nav.module.css';
import Logo from '../../assets/logo.png';
import { navMenu } from 'config/navMenu';
import { MENU_CATEGORIES, MENU_TYPES } from '@/lib/utils/constants';
import { FilePlus } from 'react-feather';
import { createPage, getPages } from '@/lib/pages';
import { useDispatch, useSelector } from 'react-redux';
import { selectPages, setPages } from 'store/appSlice';
import { usePathname } from 'next/navigation';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { User, LogOut } from 'react-feather';
import { Wrapper } from '.';

const UserMenu = ({ user, mutate, open }) => {
  const menuRef = useRef();
  const avatarRef = useRef();
  const [visible, setVisible] = useState(false);

  const router = useRouter();
  useEffect(() => {
    const onRouteChangeComplete = () => setVisible(false);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () =>
      router.events.off('routeChangeComplete', onRouteChangeComplete);
  });

  const onSignOut = useCallback(async () => {
    try {
      await fetcher('/api/auth', {
        method: 'DELETE',
      });
      toast.success('You have been signed out');
      mutate({ user: null });
      router.push('/login');
    } catch (e) {
      toast.error(e.message);
    }
  }, [mutate, router]);

  useEffect(() => {
    // detect outside click to close menu
    const onMouseDown = (event) => {
      if (
        !menuRef.current.contains(event.target) &&
        !avatarRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return (
    <div className={styles.user}>
      <button
        className={styles.trigger}
        ref={avatarRef}
        onClick={() => setVisible(!visible)}
      >
        <Avatar size={32} username={user.username} url={user.profilePicture} />
      </button>
      <div
        ref={menuRef}
        role="menu"
        aria-hidden={visible}
        className={styles.popover}
      >
        {visible && (
          <div className={styles.menu}>
            {/* <Link passHref href={`/user/${user.username}`}>
              <button className={styles.item}>Profile</button>
            </Link>
            <Link passHref href="/settings">
              <button className={styles.item}>Settings</button>
            </Link> */}
            {/* <div className={styles.item} style={{ cursor: 'auto' }}>
              <Container alignItems="center">
                <span>Theme</span>
                <Spacer size={0.5} axis="horizontal" />
                <ThemeSwitcher />
              </Container>
            </div> */}
            <button onClick={onSignOut} className={styles.item}>
              Sign out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Nav = ({ loggedIn }) => {
  const { data: { user } = {}, mutate } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const pages = useSelector(selectPages);
  const pathname = usePathname();

  const handleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const createNewPageAndNavigate = async () => {
    const newPage = await createPage();
    dispatch(setPages([...pages, newPage]));
    router.push(`/custom/${newPage._id}`);
  };

  const _navMenu = navMenu(pages);

  const onSignOut = useCallback(async () => {
    try {
      await fetcher('/api/auth', {
        method: 'DELETE',
      });
      toast.success('You have been signed out');
      mutate({ user: null });
      router.push('/login');
    } catch (e) {
      toast.error(e.message);
    }
  }, [mutate, router]);

  useEffect(() => {
    const fetchPages = async () => {
      const { pages: _pages } = await getPages();
      dispatch(setPages(_pages || []));
    };
    if (loggedIn) {
      fetchPages();
    }
  }, [loggedIn, dispatch]);

  return (
    <Box>
      <div className={styles.themeSwitcher}>
        <ThemeSwitcher />
      </div>
      {loggedIn && (
        <Drawer
          classes={{ paper: styles.sideBarContainer }}
          variant="permanent"
          open={open}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <List className={styles.list}>
            <ListItem
              disablePadding
              sx={{
                display: 'block',
              }}
            >
              <Box className={styles.logoContainer}>
                <Link href="/">
                  <Image
                    className={styles.logo}
                    src={Logo}
                    alt="yours-tradely-logo"
                  />
                </Link>

                {/* <Link className={styles.logoName} href="/">
                <div>Yours tradely</div>
              </Link> */}
              </Box>
            </ListItem>
          </List>
          {_navMenu.map((menuList) => {
            return menuList.type === MENU_TYPES.MENU_LIST ? (
              <>
                <List className={styles.list}>
                  {menuList.items
                    .filter(({ hidden }) => !hidden)
                    .map(({ label, Icon, link }) => (
                      <ListItem
                        key={label}
                        disablePadding
                        sx={{
                          display: 'block',
                        }}
                        className={
                          pathname === link ? styles.selectedListItem : ''
                        }
                      >
                        <Link passHref className={styles.link} href={link}>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                            className={styles.item}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              <Icon className={styles.icon} />
                            </ListItemIcon>
                            {open && (
                              <ListItemText
                                sx={{
                                  m: 0,
                                }}
                                classes={{ primary: styles.pageTitle }}
                                primary={label}
                              />
                            )}
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    ))}
                  {menuList.category === MENU_CATEGORIES.CUSTOM_PAGES && (
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      className={styles.item}
                      onClick={createNewPageAndNavigate}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <FilePlus className={styles.icon} />
                      </ListItemIcon>
                      {open && (
                        <ListItemText
                          sx={{ m: 0 }}
                          classes={{ primary: styles.pageTitle }}
                          primary={'New page'}
                        />
                      )}
                    </ListItemButton>
                  )}
                </List>
              </>
            ) : menuList.type === MENU_TYPES.DIVIDER ? (
              <Divider />
            ) : (
              <></>
            );
          })}
          <Divider />
          <List disablePadding>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={handleProfileMenu}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <User className={styles.icon} />
              </ListItemIcon>
              {open && (
                <>
                  <ListItemText
                    sx={{ m: 0 }}
                    classes={{ primary: styles.pageTitle }}
                    primary={'Profile'}
                  />
                  {profileMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </>
              )}
            </ListItemButton>
          </List>
          {open && (
            <Collapse in={profileMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={onSignOut}
                  className={styles.item}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <LogOut className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </ListItemButton>
              </List>
            </Collapse>
          )}
        </Drawer>
      )}
    </Box>
  );
};

export default Nav;
