import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  queryResults: {
    data: [],
    columns: {
      min: [],
      max: [],
    },
  },
};

// Actual Slice
export const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    // Action to set query results
    setQueryResults(state, action) {
      state.queryResults = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.query,
      };
    },
  },
});

export const { setQueryResults } = querySlice.actions;

export const selectQueryResults = (state) => state.query.queryResults;

export default querySlice.reducer;
