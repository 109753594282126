import { configureStore } from '@reduxjs/toolkit';
import { querySlice } from './querySlice';
import { createWrapper } from 'next-redux-wrapper';
import { analysisSlice } from './analysisSlice';
import { appSlice } from './appSlice';
import { streamSlice } from './streamSlice';

const makeStore = () =>
  configureStore({
    reducer: {
      [querySlice.name]: querySlice.reducer,
      [analysisSlice.name]: analysisSlice.reducer,
      [appSlice.name]: appSlice.reducer,
      [streamSlice.name]: streamSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore specific paths in the state
          ignoredPaths: ['stream.stream.connection'],
        },
      }),
    devTools: true,
  });

export const wrapper = createWrapper(makeStore);
