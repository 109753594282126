import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  user: {
    isLoading: true,
    isValid: false,
  },
  pages: [],
  theme: 'light',
};

// Actual Slice
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // Action to set query results
    setUser(state, action) {
      state.user = action.payload;
    },
    setPages(state, action) {
      state.pages = action.payload;
    },
    updatePage(state, action) {
      const updatedPage = action.payload;

      state.pages = state.pages.map((page) => {
        return page._id === updatedPage._id
          ? { ...page, ...updatedPage }
          : page;
      });
    },
    setTheme(state, action) {
      state.theme = action.payload.theme;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.app,
      };
    },
  },
});

export const { setUser, setPages, updatePage, setTheme } = appSlice.actions;

export const selectUser = (state) => {
  return state.app?.user;
};

export const selectPages = (state) => {
  return state.app?.pages;
};

export const selectTheme = (state) => {
  return state.app?.theme;
};

export default appSlice.reducer;
